import React, { useState, useEffect, useRef, useContext } from "react";
import Loading from "./assets/loading8.gif";
import Logo from "./assets/DreyfusLogoSite.png";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './gerCotacoes.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { scrollTo } from 'scroll-js';
import Hotkeys from 'react-hot-keys';
var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  return "<i class='fa fa-trash-o'></i>";
  //} else {
  //  return "-";
  //}
}

function editarContrato(numprop, nome, tipo, sindico, eleito, rg, cpf, profiss, estadocivil, nomet1, rgt1, nomet2, rgt2) {
  gGlobal.gTipo = tipo;
  gGlobal.gProp = numprop;
  let ele = document.getElementById('panelActions');
  ele.style.display = '';
  //let eleCard = document.getElementById('cardGridContr');
  //eleCard.classList.add("collapsed-card");
  let ele2 = document.getElementById('tituloContr');
  ele2.textContent = "Dados para o Contrato da proposta nº " + numprop + " cliente: " + nome;
  document.getElementById('inputNomeS').value = sindico;
  document.getElementById('inputDtEleito').value = eleito;
  document.getElementById('inputRG').value = rg;
  document.getElementById('inputCPF').value = cpf;
  document.getElementById('inputProf').value = profiss;
  document.getElementById('inputEstadoCivil').value = estadocivil;
  document.getElementById('inputNomet1').value = nomet1;
  document.getElementById('inputRGt1').value = rgt1;
  document.getElementById('inputNomet2').value = nomet2;
  document.getElementById('inputRGt2').value = rgt2;

}



function gerContratoLink(params) {

  window.location.href = "#/contratos-gerenciar";


}

function geraWordContr(ncontr) {
  const linkurl = "https://api.dreyfusvieira.com.br/docxcontr.php?apikey=398738497834758934759834758934&numcontr=" + ncontr;
  window.open(linkurl, '_blank');

}

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};

function Gerador() {

  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [AddContr, setAddContr] = useState([]);
  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);
  const isFirstRunDados = useRef(true);


  useEffect(() => {


  }, [rep])


  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
      return;
    }
    let ignore = false;
    const { prop, sindico, eleito, rg, cpf, profiss, estadocivil, nomet1, rgt1, nomet2, rgt2, tipo } = AddContr;
    //cl("AddContr", AddContr)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('prop', prop);
      bodyFormData.set('sindico', sindico);
      bodyFormData.set('eleito', eleito);
      bodyFormData.set('rg', rg);
      bodyFormData.set('cpf', cpf);
      bodyFormData.set('profiss', profiss);
      bodyFormData.set('estadocivil', estadocivil);
      bodyFormData.set('nomet1', nomet1);
      bodyFormData.set('rgt1', rgt1);
      bodyFormData.set('nomet2', nomet2);
      bodyFormData.set('rgt2', rgt2);
      bodyFormData.set('tipo', tipo);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: 'https://api.dreyfusvieira.com.br/newcontr.php?apikey=398738497834758934759834758934',
        //url: 'http://jvfapp.ddns.net:3333/newcontacts',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { cl("reponse", response) };
      // async function fetchdata() {
      //   const resp = await fetch('http://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        setAtu({ '0': '0' });
        //cl('setatu 0 0')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddContr])


  useEffect(() => {
/*     if (isFirstRunDados.current) {
      isFirstRunDados.current = false;
      //cl("FirstRunDados false")
      return;
    } */
    let ignore = false;
    //let eleLoading = document.getElementById('divLoad');
    //if (eleLoading.style.display == 'none') {
    //  eleLoading.style.display = '';
    //}
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.dreyfusvieira.com.br/listaTiposOcorrencias.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      // async function fetchdata() {
      //   const resp = await fetch('http://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setrep(redata2);
        } else {
          setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if(eleLoading) {eleLoading.style.display = 'none';}
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  const ref = null;

  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, hozAlign: "center", resizable: false, responsive: 0 },
    // { title: 'Id', field: 'id', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    //{
    //  title: 'Data', field: 'createdAt', hozAlign: 'center', width: 66, responsive: 0, formatter: "datetime", formatterParams: {
    //    inputFormat: "YYYY-MM-DD",
    //    outputFormat: "DD/MM/YY",
    //    invalidPlaceholder: "(invalid date)",
    //  }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    //},
    // // { title: 'Data', field: 'createdAt' ,hozAlign: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Auditoria', field: 'auditoria', hozAlign: "left", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Tipo', field: 'nome', hozAlign: "Left", width: 200, responsive: 0 },
    { title: 'Pasta', field: 'pasta', hozAlign: "center", width: 70, responsive: 0, },
    { title: 'Pagina', field: 'pagina', hozAlign: "center", width: 70, responsive: 0, },
    { title: 'Emitente', field: 'emitente', hozAlign: "center", width: 70, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Descrição', field: 'desc', hozAlign: "center", width: 70, responsive: 0, },
    //{ title: 'Sindico', field: 'sindico', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'NF Nº', field: 'nfe_num', hozAlign: "center", width: 70, responsive: 0, headerSort: false },
    { title: 'NF Data', field: 'nfe_data', hozAlign: "center", width: 70, responsive: 0, headerSort: false },
    { title: 'NF Valor', field: 'nfe_vlr', hozAlign: "center", width: 70, responsive: 0, headerSort: false },
    { title: 'vlr Pago', field: 'nfe_vlr_pg', hozAlign: "center", width: 70, responsive: 0, headerSort: false },
    { title: 'Vlr Dif.', field: 'dif', hozAlign: "center", width: 70, responsive: 0, headerSort: false },
    { title: 'Cidade', field: 'cidade', hozAlign: "center", width: 70, responsive: 0, },
    { title: 'PCC', field: 'pcc', hozAlign: "center", width: 70, responsive: 0, headerSort: false },
    //{ title: 'Valor Parcelas', field: 'vlr_parc', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    //{ title: 'Prazo', field: 'qtdDias', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    //{ title: 'Editar', formatter: editIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { editarContrato(cell.getRow().getData().prop, cell.getRow().getData().nome, cell.getRow().getData().tipo, cell.getRow().getData().sindico, cell.getRow().getData().eleito, cell.getRow().getData().rg, cell.getRow().getData().cpf, cell.getRow().getData().profiss, cell.getRow().getData().estadocivil, cell.getRow().getData().nomet1, cell.getRow().getData().rgt1, cell.getRow().getData().nomet2, cell.getRow().getData().rgt2) } },
    //{ title: 'Gerar', formatter: wordIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { geraWordContr(cell.getRow().getData().numcontr) } },
    //{ title: 'Deletar', formatter: iconLixeira, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().nome) } },
    //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { gGlobal.gContrato = cell.getRow().getData().prop; gerContratoLink(); } },

    // { title: 'Nome', field: 'nameC', width: 150, responsive: 0, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    // { title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    // { title: 'Cidade/Estado', field: 'cidstate', hozAlign: 'center', width: 120, responsive: 0 },
    // { title: 'Fone', field: 'fone', hozAlign: 'center', width: 100, responsive: 0 },
    // { title: 'Origem', field: 'origem', hozAlign: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', hozAlign: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', hozAlign: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, hozAlign: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', hozAlign: "center", width: 80, headerSort: false, formatter: infoIcon, responsive: 0, cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Q?', field: 'resp', headerSort: false, hozAlign: "center", formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: '@', formatter: emailIcon, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { iconSendMail(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
    //{ title: 'Del', formatter: iconLixeira, width: 25, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().progress < 11){ confirmDel(cell.getRow().getData().nameC, cell.getRow().getData().chave) }} },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    //{ title: 'Chave', field: 'chave', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    // { title: 'Custom', field: 'custom', hozAlign: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', hozAlign: 'center' },
  ];

  const columnsProp = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, hozAlign: "center", resizable: false, responsive: 0 },
    //{ title: 'Id', field: 'id', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    // {
    //  title: 'Data', field: 'createdAt', hozAlign: 'center', width: 80, responsive: 0, formatter: "datetime", formatterParams: {
    //    inputFormat: "YYYY-MM-DD",
    //   outputFormat: "DD/MM/YY",
    //   invalidPlaceholder: "(invalid date)",
    // }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    // },
    // { title: 'Data', field: 'createdAt' ,hozAlign: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    { title: 'Chave', field: 'chave', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Tipo', field: 'tipo', hozAlign: "center", width: 200, responsive: 0, },
    { title: 'Numero', field: 'prop', hozAlign: "center", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Valor Total', field: 'vlr_total', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'Parcelas', field: 'parc', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    { title: 'Valor Parcelas', field: 'vlr_parc', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'Prazo', field: 'qtdDias', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    //{ title: 'Download', formatter: wordIcon, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { geraWordProp(cell.getRow().getData().chave, cell.getRow().getData().prop) } },
    //{ title: 'Deletar', formatter: iconLixeira, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().tipo, cell.getRow().getData().vlr_total, cell.getRow().getData().chave) } },
    //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { alert('Finalizando o desenvolvimento...') } },
    //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    //{ title: 'Cidade/Estado', field: 'cidstate', hozAlign: 'center', width: 120, responsive: 0 },
    //{ title: 'Fone', field: 'fone', hozAlign: 'center', width: 130, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Origem', field: 'origem', hozAlign: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', hozAlign: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', hozAlign: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, hozAlign: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Q?', field: 'resp', headerSort: false, formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    // { title: 'Custom', field: 'custom', hozAlign: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', hozAlign: 'center' },
  ];

  // const data = [
  //   { id: 1, name: 'Oli Bob', age: '12', color: 'red', dob: '01/01/1980', rating: 5, passed: true, pets: ['cat', 'dog'] },
  //   { id: 2, name: 'Mary May', age: '1', color: 'green', dob: '12/05/1989', rating: 4, passed: true, pets: ['cat'] },
  //   { id: 3, name: 'Christine Lobowski', age: '42', color: 'green', dob: '10/05/1985', rating: 4, passed: false },
  //   { id: 4, name: 'Brendon Philips', age: '125', color: 'red', dob: '01/08/1980', rating: 4.5, passed: true },
  //   { id: 5, name: 'Margret Marmajuke', age: '16', color: 'yellow', dob: '07/01/1999', rating: 4, passed: false },
  //   {
  //     id: 6,
  //     name: 'Van Ng',
  //     age: '37',
  //     color: 'green',
  //     dob: '06/10/1982',
  //     rating: 4,
  //     passed: true,
  //     pets: ['dog', 'fish']
  //   },
  //   { id: 7, name: 'Duc Ng', age: '37', color: 'yellow', dob: '10/10/1982', rating: 4, passed: true, pets: ['dog'] }
  // ];

  // // Editable Example:
  // const colorOptions = { '': '&nbsp;', red: 'red', green: 'green', yellow: 'yellow' };
  // const petOptions = [{ id: 'cat', name: 'cat' }, { id: 'dog', name: 'dog' }, { id: 'fish', name: 'fish' }];
  const options = {
    height: 'auto',
    // movableRows: true,
    clipboard: true,
    invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "nome", dir: "asc" },
      { column: "auditoria", dir: "asc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  function novaOcorrencia(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';
    scrollTo(window, { top: 500 }).then(function () {
      // window has scrolled 500 pixels down the page
    });
  }

  function confirmDelProp(numprop, cliente) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este Contrato da proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                /*                 setDelContr(
                                  {
                                    'numprop': numprop
                                  }); */
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function dataTable(params) {

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardOcorrencias" style={{ display: '' }}>
          <div className="card-header ui-sortable-handle gertitle locCont" >
            <h3 className="card-title locCont">Tipos de Ocorrências Existentes</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}
              <Hotkeys
                keyName="shift+o"
                onKeyDown={novaOcorrencia}
              //onKeyUp={novoContato}
              />
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={novaOcorrencia} style={{ margin: '-3px 20px' }}>Nova Ocorrência &#40;Shift+O&#41;</button> */}
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>
          <div className="card-body locCont" id="cardGridBggBody">
            <ReactTabulator
              ref={(ref)}
              columns={columns}
              data={rep}
              rowClick={rowClick}
              cellClick={rowClick}
              options={options}
              data-custom-attr="test-custom-attribute"
              className="#example-table-theme"
            />
            {/* <div className="divLineBar">
            <img id="imgLineBar" width="auto10%" alt="Loading.." src={LineBar}></img>
            </div> */}
            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>
        </div>
      </>
    )

  }

  function pageAtivar(params) {
    return (
      <div id="panelAtivar" name="panelAtivar" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-warning cardAddBgg" id="panelAtiva">
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContrAtivo">Ative o Cliente na Lista Abaixo</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" id="ipanelAtiva" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardpanelAtiva">
                  {/* Conversations are loaded here */}
                  <form id="formAtivarCliente">
                    <div className="form-row">
                      <div className="form-group">
                        <label >Selecione abaixo</label>
                        <select className="form-control" id="selectAtivar" placeholder="Selecione abaixo o cliente que irá lançar as Ocorrências" >
                          <option value='0'>Selecione</option>
                        </select>
                      </div>
                    </div>

                    <button type="button" className="btn btn-info " id="btnAtivar" onClick={e => AtivarCliente(document.getElementById('selectAtivar').value)}>Ativar</button>
                  </form>




                </div>

                <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function page1(params) {
    return (
      <div id="panelActions" name="panelActions" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Dados para o Contrato</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label htmlFor="inputNomeS">Nome do Sindico(a)</label>
                        <input type="text" className="form-control" id="inputNomeS" placeholder="Nome" />
                      </div>
                      <div className="form-group col-md-4">
                        <div className="form-group ">
                          <label >Eleito em </label>
                          <input type="date" className="form-control" id="inputDtEleito" placeholder="eleio em" />
                        </div>

                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label htmlFor="inputRG">RG</label>
                        <input type="text" className="form-control" placeholder="11.111.111-1 ssp-sp" id="inputRG" />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="inputCPF">CPF</label>
                        <input type="text" className="form-control" placeholder="111.111.111-11" id="inputCPF" />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="inputProf">Profissão</label>
                        <input type="text" className="form-control" placeholder="Ex: advogado" id="inputProf" />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="inputEstadoCivil">Estado Civil</label>
                        <input type="text" className="form-control" placeholder="Ex: divorciado" id="inputEstadoCivil" />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label >Testemunha 1</label>
                        <input type="text" className="form-control" id="inputNomet1" placeholder="Nome" />
                      </div>
                      <div className="form-group col-md-4">
                        <div className="form-group ">
                          <label >RG</label>
                          <input type="text" className="form-control" id="inputRGt1" placeholder="11.111.111-1 ssp-sp" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label >Testemunha 2</label>
                        <input type="text" className="form-control" id="inputNomet2" placeholder="Nome" />
                      </div>
                      <div className="form-group col-md-4">
                        <div className="form-group ">
                          <label >RG</label>
                          <input type="text" className="form-control" id="inputRGt2" placeholder="11.111.111-1 ssp-sp" />
                        </div>
                      </div>
                    </div>

                    <button type="button" className="btn btn-info " id="btnGravar" onClick={e => addNewContr(gGlobal.gProp, gGlobal.gTipo)}>Gravar</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditContr()}>Fechar</button>
                  </form>




                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function validaNome(params) {
    const eNnome = document.getElementById('inputNomeS')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCid')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    im.mask(eNnome);

  }

  function addNewContr(prop, tipo) {
    setAddContr(
      {
        'prop': prop,
        'sindico': document.getElementById('inputNomeS').value,
        'eleito': document.getElementById('inputDtEleito').value,
        'rg': document.getElementById('inputRG').value,
        'cpf': document.getElementById('inputCPF').value,
        'profiss': document.getElementById('inputProf').value,
        'estadocivil': document.getElementById('inputEstadoCivil').value,
        'nomet1': document.getElementById('inputNomet1').value,
        'rgt1': document.getElementById('inputRGt1').value,
        'nomet2': document.getElementById('inputNomet2').value,
        'rgt2': document.getElementById('inputRGt2').value,
        'tipo': tipo
      });
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    const eActions = document.getElementById('panelActions')
    eActions.style.display = 'none';

  }

  function AtivarCliente(cliente, numcontr) {
    let numc = cliente.substring(cliente.indexOf("º") + 1);
    gGlobal.gContrato = numc;
    //cl('numc',numc);
    setAtu({ '0': '0' });
    let eleCardOroc = document.getElementById('cardOcorrencias');
    if (eleCardOroc) { eleCardOroc.style.display = ''; }

    let eletituloContrAtivo = document.getElementById('tituloContrAtivo');
    eletituloContrAtivo.innerHTML = cliente;
    let elepanelAtivo = document.getElementById('panelAtiva');
    elepanelAtivo.classList.add("collapsed-card");
    let eleCardpanelAtivo = document.getElementById('cardpanelAtiva');
    eleCardpanelAtivo.style.display = 'none';
    let eleIpanelAtivo = document.getElementById('ipanelAtiva');
    eleIpanelAtivo.classList.remove("fa-minus");
    eleIpanelAtivo.classList.add("fa-plus");
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
  }

  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';
    let eleCard = document.getElementById('cardGridContr');
    eleCard.classList.remove("collapsed-card");
    gGlobal.gContrato = '';
  }

  if (rep.length < 1) {
    //if (0 == 1) {
    return (
      <div className="content-wrapper">
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando Tipos de Ocorrências ...</div>
      </div>
    )
  } else {
    return (
      <div className="content-wrapper">
        {dataTable()}

      </div>
    )

  }


}

export default Gerador   