import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { cl } from './components/functions';
import './menu.css'


function Relatorios() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-file-pdf-o" />
                <p>
                Ocorrências
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/visualizar-atas" className="nav-link">
                        <i className="fa fa-pencil-square-o nav-icon" />
                        <p>Lançamentos
                    </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-comunicados" className="nav-link">
                        <i className="fa fa-list-ol nav-icon" />
                        <p>Tipos
                    </p>
                    </Link>
                </li>

            </ul>

        </li>
    );
}

function RelatoriosMembro() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-file-pdf-o" />
                <p>
                Ocorrências
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/visualizar-atas" className="nav-link">
                        <i className="fa fa-pencil-square-o nav-icon" />
                        <p>Lançamentos
                    </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/visualizar-comunicados" className="nav-link">
                        <i className="fa fa-list-ol nav-icon" />
                        <p>Tipos
                    </p>
                    </Link>
                </li>

            </ul>

        </li>
    );
}

function CotacoesMenu() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-handshake-o" />
                <p>
                    Cotações
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/cotacoes-gerenciar" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Gerenciar
                    </p>
                    </Link>
                </li>

            </ul>

        </li>
    );
}

function OcorrenciasMenu() {

    return (
        <li className="nav-item has-treeview menu-open" >

        <Link to="/" className="nav-link">
            <i className="nav-icon fas fa-database" />
            <p>
            Ocorrências
            <i className="fas fa-angle-left right" />
                {/* <span className="badge badge-info right">4</span> */}
            </p>
        </Link>
        <ul className="nav nav-treeview">
            <li className="nav-item">
                <Link to="/ocorrencias" className="nav-link">
                    <i className="fas fa-radiation nav-icon" />
                    <p>Lançamentos
                    <span className="right badge badge-danger">Novo</span>
                    </p>
                </Link>
            </li>
        </ul>
        <ul className="nav nav-treeview">
            <li className="nav-item">
                <Link to="/tiposocorrencias" className="nav-link">
                    <i className="fa fa-list-ol nav-icon" />
                    <p>Tipos
                    <span className="right badge badge-danger">Novo</span>
                    </p>
                </Link>
            </li>
        </ul>
    </li>
    );
}


class Menu extends Component {
    componentDidMount() {
        //window.addEventListener('load', this.handleSignOut);

        // const TOKEN_KEY = "KEY_APP_BGG";
        // const token = localStorage.getItem(TOKEN_KEY);
        // var base64Url = token.split('.')[1];
        // var decodedValue = JSON.parse(window.atob(base64Url));
        // console.log(decodedValue);
        // var novoObj = decodedValue;
        // let nome = novoObj.nome;
        // console.log(nome);

        // let buff = new Buffer(token, 'base64'); 
        // if (typeof Buffer.from === "function") {
        //     // Node 5.10+
        //     buff = Buffer.from(buff, 'base64'); // Ta-da
        // } else {
        //     // older Node versions, now deprecated
        //     buff = new Buffer(buff, 'base64'); // Ta-da
        // } 
        // let text = buff.toString('ascii');
        
    }
    render() {
        //const TOKEN_KEY = "KEY_APP_BGG";
        //const token = localStorage.getItem(TOKEN_KEY);
        //var base64Url = token.split('.')[1];
        //var decodedValue = JSON.parse(window.atob(base64Url));
        //console.log(decodedValue);
        //var novoObj = decodedValue;
        //let nome = novoObj.nome;
        //console.log(nome);
        let itemmenu1;
        let itemmenu2;
        let itemmenu3;
        
        if (global.gNome !== '') {
            itemmenu1 = <li className="nav-item"><Link to="/visualizar-prestacao-de-contas" className="nav-link"><i className="fa fa-bar-chart nav-icon" /><p>Prestação de Contas</p></Link></li>;
            itemmenu2 = CotacoesMenu();
            itemmenu3 = OcorrenciasMenu();
            //cl("gCargo",global.gCargo);
            if ((global.gCargo < 1) || (global.gCargo === '') || (global.gCargo === undefined) || (global.gCargo === null)){
                //itemmenu3 = Relatorios();
            } else { 
                //itemmenu3 = RelatoriosMembro();
            }
        } else {
            itemmenu1 = '';
            itemmenu2 = '';
            itemmenu3 = '';
        }


        
        return (
            <div >
                <aside className="main-sidebar sidebar-dark-primary elevation-4"  >
                    {/* Brand Logo */}
                    <Link to="/" className="brand-link">
                        <img src="dist/img/logoSupDreyfus.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">Dreyfus - App</span>
                    </Link>
                    {/* Sidebar */}


                    <div className="sidebar">
                        <div className="divMenuBgg" style={{ backgroundImage: 'url("/images/bg-01.jpg")' }}></div>


                        {/* Sidebar user panel (optional) */}
                        {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                            </div>
                            <div className="info">
                                <Link to="/" className="d-block">{nome}</Link>
                            </div>
                            {/* <form className="form-inline ml-3">
                                <div className=" input-group input-group-sm">
                                    <input className="form-control form-control-navbar" type="search" placeholder="Pesquisar" aria-label="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-navbar" type="submit">
                                            <i className="fas fa-search" />
                                        </button>
                                    </div>
                                </div>
                            </form> 
                        </div> */}

                        {/* Sidebar Menu */}
                        <nav className="mt-2" >
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                                { /*       <li className="nav-item has-treeview menu-close">
                                    <a href="fake_url" className="nav-link active">
                                        <i className="nav-icon fas fa-tachometer-alt" />
                                        <p>
                                            Dashboard
                                            <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="./index.html" className="nav-link active">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v1</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="./index2.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v2</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="./index3.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v3</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                        */}
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Painel Inicial
                                        </p>
                                    </Link>
                                </li>
                                {itemmenu2}
                                <li className="nav-item has-treeview menu-open" >

                                    <Link to="/" className="nav-link">
                                        <i className="nav-icon fa fa-line-chart" />
                                        <p>
                                        Contratos
                                        <i className="fas fa-angle-left right" />
                                            {/* <span className="badge badge-info right">4</span> */}
                                        </p>
                                    </Link>
                                    <ul className="nav nav-treeview">
                                        {/* <li className="nav-item">
                                            <Link to="/cotacoes-consulta" className="nav-link">
                                            <i className="fa fa-inbox nav-icon" />
                                            <p>Novas</p>
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link to="/contratos-gerenciar" className="nav-link">
                                                <i className="fa fa-cogs nav-icon" />
                                                <p>Gerenciar
                                                </p>
                                            </Link>
                                        </li>
                                        {/* {itemmenu1} */}



                                        {/* <li className="nav-item">
                                            <Link to="/cotacoes-gerenciar" className="nav-link">
                                                <i className="fa fa-cogs nav-icon" />
                                                <p>Arquivadas
                                                <span className="right badge badge-danger">Desenv</span>
                                                </p>
                                            </Link>
                                        </li> */}

                                        {/*
                                        <li className="nav-item">
                                            <a href="pages/layout/fixed.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Fixed</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/layout/fixed-topnav.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Fixed Navbar</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/layout/fixed-footer.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Fixed Footer</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="pages/layout/collapsed-sidebar.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Collapsed Sidebar</p>
                                            </a>
                                        </li>
                                        */}
                                    </ul>
                                </li>
                                
                                {itemmenu3}


                                <li className="nav-header"></li>
                                <li className="nav-item">
                                    {/* <Link to="/logout" className="nav-link">
                                        <i className="nav-icon fas fa-close" />
                                        <p>Sair</p>
                                    </Link> */}
                                </li>
                                <li className="nav-header"></li>
                                <li className="nav-item">
                                    {/* <Link to="/logout" className="nav-link">
                                        <i className="nav-icon fas fa-close" />
                                        <p>Sair</p>
                                    </Link> */}
                                </li>
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>


            </div>
        )
    }
}
export default Menu