/* eslint-disable */
import React, { Component } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { cl } from './components/functions';



var gGlobal = require('./components/globalvar.js');
var pjson = require('../package.json');
global.gVersion = pjson.version

/* jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
}; */

const TOKEN_KEY = "KEY_APP_BGG";
global.gIdUser = 0;

class HeaderIn extends Component {


    logout = e => {
        //console.log("logout");
        const teste = sessionStorage.removeItem(TOKEN_KEY);
        if (teste) { };
        {/* <Navigate to="/login" />; */ }
        //this.props.history.push("/login");
        window.open("/login", "_self");
    }

    componentDidMount() {
        //       window.addEventListener('load', this.handleSignOut);

        //        const TOKEN_KEY = "KEY_APP_BGG";
        //        const token = localStorage.getItem(TOKEN_KEY);
        //      var base64Url = token.split('.')[1];
        //       var decodedValue = JSON.parse(window.atob(base64Url));
        //console.log(decodedValue);
        //       var novoObj = decodedValue;
        //       const nome = novoObj.nome;
        //       //console.log(nome);

        // let buff = new Buffer(token, 'base64'); 
        // if (typeof Buffer.from === "function") {
        //     // Node 5.10+
        //     buff = Buffer.from(buff, 'base64'); // Ta-da
        // } else {
        //     // older Node versions, now deprecated
        //     buff = new Buffer(buff, 'base64'); // Ta-da
        // } 
        // let text = buff.toString('ascii');        
    }
    render() {


        function b64DecodeUnicode(str) {
            return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            }).join(''))
        }

        //tamanho da tela do browser
        //const deskH = window.innerHeight;        
        //const deskW = window.innerWidth;

        // function b64EncodeUnicode(str) {
        //     return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        //         return String.fromCharCode(parseInt(p1, 16))
        //     }))
        // }

        const token = sessionStorage.getItem(TOKEN_KEY);
        //cl("Tokennn2",token)
        if (sessionStorage.getItem(TOKEN_KEY) == "null") {
            sessionStorage.removeItem(TOKEN_KEY);
            this.props.history.push("/");
        };
        const base64Url = token.split('.')[1];
        //cl("base",base64Url);
        const decodedValue = JSON.parse(b64DecodeUnicode(base64Url));
        //console.log("decode",decodedValue);
        const novoObj = decodedValue;
        //cl("novoObj",novoObj);
        global.gNome = novoObj[0].nome;
        global.gTipoUser = novoObj[0].tipo;
        global.gDoc = novoObj[0].doc;
        global.gCond = novoObj[0].cod;
        global.gNomecond = novoObj[0].nomecond;
        global.gEmail = novoObj[0].email;
        global.gIdUser = novoObj[0].id;
        global.gIDProf = novoObj[0].idprof;
        global.gNewVersion = novoObj[0].ver;
        //cl("global.gNewVersion",global.gNewVersion)
        //global.gLinkBase = "http://adminrl.dyndns.org:8085";
        //cl("gnome",global.gNome);
       // cl("gTipoUser header",global.gTipoUser);
        //cl("gDoc",global.gDoc);
        //cl("gCond",global.gCond);
        //cl("gEmail",global.gEmail);
        global.gListCond = '';
        global.gaListCond = [];
        for (var i in novoObj) {
            if (i > 0) {
                global.gListCond = global.gListCond + ',';
            }
            global.gListCond = global.gListCond + novoObj[i].cod;

            global.gaListCond[novoObj[i].cod] =
            {
                "nome": novoObj[i].nomecond,
                "bl": novoObj[i].bloco,
                "unid": novoObj[i].unidade
            };

        }

        //cl("gListCond for ",global.gListCond);

        //cl("gaListCond for ",global.gaListCond);

        //console.log(nome);  


        return (
            <div>

                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <title>AdminLTE 3 | Dashboard</title>
                {/* Tell the browser to be responsive to screen width */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <nav className="main-header navbar navbar-expand navbar-white navbar-light border-bottom">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="#" className="nav-link" data-widget="pushmenu" ><i className="fa fa-angle-double-left fa-2x" style={{color : 'red'}}/><i className="fa fa-angle-double-left fa-2x" style={{color : 'red'}}/></Link>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            {/* <Link to="/" className="nav-link" >{global.Nomecond}</Link> */}
                            <div id="Hnomecond" name="Hnomecond" className="nav-link"></div>
                        </li>

                    </ul>
                    {/* SEARCH FORM */}
                    {/* <form className="form-inline ml-3">
                        <div className="input-group input-group-sm">
                            <input className="form-control form-control-navbar" type="search" placeholder="Pesquisar" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-navbar" type="submit">
                                    <i className="fas fa-search" />
                                </button>
                            </div>
                        </div>
                    </form> */}
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        {/* Messages Dropdown Menu */}
                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link" data-toggle="dropdown" to="/">
                                <i className="far fa-comments" />
                                <span className="badge badge-danger navbar-badge">3</span>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <Link to="/" className="dropdown-item">
                                    {/* Message Start */}
                        {/* <div className="media">
                                        <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                                        <div className="media-body">
                                            <h3 className="dropdown-item-title">
                                                Brad Diesel
                <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
                                            </h3>
                                            <p className="text-sm">Call me whenever you can...</p>
                                            <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                        </div>
                                    </div> */}
                        {/* Message End */}
                        {/* </Link>
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item"> */}
                        {/* Message Start */}
                        {/* <div className="media">
                                        <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                                        <div className="media-body">
                                            <h3 className="dropdown-item-title">
                                                John Pierce
                <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
                                            </h3>
                                            <p className="text-sm">I got your message bro</p>
                                            <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                        </div>
                                    </div> */}
                        {/* Message End */}
                        {/* </Link>
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item"> */}
                        {/* Message Start */}
                        {/* <div className="media">
                                        <img src="dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                                        <div className="media-body">
                                            <h3 className="dropdown-item-title">
                                                Nora Silvester
                <span className="float-right text-sm text-warning"><i className="fas fa-star" /></span>
                                            </h3>
                                            <p className="text-sm">The subject goes here</p>
                                            <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                        </div>
                                    </div> */}
                        {/* Message End */}
                        {/* </Link>
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item dropdown-footer">See All Messages</Link>
                            </div>
                        </li>  */}
                        {/* Notifications Dropdown Menu */}
                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link" data-toggle="dropdown" to="/">
                                <i className="far fa-bell" />
                                <span className="badge badge-warning navbar-badge" id="spanSino" name="spanSino">15</span>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">15 Avisos</span>
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item">
                                    <i className="fas fa-envelope mr-2" /> 4 novas cotações
          <span className="float-right text-muted text-sm">3 min</span>
                                </Link>
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item">
                                    <i className="fas fa-users mr-2" /> 8 respostas 
          <span className="float-right text-muted text-sm">12 horas</span>
                                </Link>
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item">
                                    <i className="fas fa-file mr-2" /> 3 vencimentos
          <span className="float-right text-muted text-sm">2 dias</span>
                                </Link>
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item dropdown-footer">Veja todas Notificações</Link>
                            </div>
                        </li> */}

                        {/*deskW+" x "+deskH*/}
                        <li className="media" id="headerlogo">
                                <img src="dist/img/MB_LoGo.png" className="user-image-bgg" alt="User Image1" />
                                <span class="brand-text font-weight-light">MARCIO BAGAGINE</span>
                        </li>
                        <li className="nav-item dropdown user user-menu open">
                            <Link to="#" className="nav-link" data-toggle="dropdown" aria-expanded="true">
                                <img src="dist/img/user3-160x160.jpg" className="user-image-bgg" alt="User Image1" />
                                <span className="hidden-xs">{global.gNome}</span>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">

                                {/* User image */}
                                <li id="fbggulusu" name="fbggulusu" className="user-header">
                                    <img src="dist/img/user3-160x160.jpg" className="img-circle" alt="User Image2" />
                                    <p>
                                        {global.gNome}
                                        {/* <small>Member since Nov. 2012</small> */}
                                    </p>
                                    <p id="p_ip">

                                        {/* <small>Member since Nov. 2012</small> */}
                                    </p>
                                </li>
                                {/* Menu Body */}

                                {/* <li className="user-body">
                                    <div className="row">
                                        <div className="col-xs-4 text-center">
                                            <Link to="#">Avisos</Link>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <Link to="#">Tarefas</Link>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <Link to="#">Log</Link>
                                        </div>
                                    </div>
                                    {/* /.row */}
                                {/* </li> */}
                                {/* Menu Footer*/}
                                <li className="user-footer">
                                    <div className="pull-left">
                                        <Link to="#" className="btn btn-default btn-flat">Meus dados</Link>
                                    </div>
                                    <div className="pull-right">
                                        <Link to="" className="btn btn-default btn-flat " data-toggle="modal" data-target="#modal-default">Sair</Link>
                                    </div>
                                </li>
                            </ul>
                        </li>


                        {/* FINAL */}
                        {/* <li className="nav-item">
                            <Link className="nav-link" data-widget="control-sidebar" data-slide="true" to="/">
                                <i className="fas fa-th-large" />
                            </Link>
                        </li> */}
                    </ul>
                </nav>
                <div className="modal fade" id="modal-default" style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Deseja sair do sistema ?</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {/* <div className="modal-body">
                                <p>Deseja sair do sistema ?</p>
                            </div> */}
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.logout}>Sim</button>
                                <button type="button" className="btn btn-default" data-dismiss="modal">Não</button>
                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                        {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                </div>

            </div>
        )
    }
}

export default HeaderIn
